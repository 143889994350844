<template>
  <div class="ma-10">
    <v-form>
      <v-row>
        <v-col cols="12">
          <form-step-title :title="`Vous représentez ${entite.nom}`" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="mb-8">
            Votre établissement dépend du regroupement d'établissements {{ esr.nom }}
          </div>
          <form-fields-multi-select 
            v-model="type_organisation_id"
            field-label="L'établissement est du type" 
            label="Liste des types d'organisations"
            :items="type_organisations"
            item-text="nom"
            item-value="id"
            class="mt-1"
            required
            @change="saveEntite"
          />
          <form-fields-multi-select 
            v-if="type_organisation.nom === 'Laboratoire'"
            v-model="dependance_directe_id"
            field-label="Dépendance directe" 
            label="Liste des dépendances"
            :items="entites"
            item-text="nom"
            item-value="id"
            required
            @change="saveEntite"
          />
        </v-col>
      </v-row>
      <base-subtitle 
        :subtitle="type_organisation ? `Sites géographiques de ${entite.nom}` : 'Sites géographiques'" 
        divider 
        class="mb-2"
      />
      <v-row class="justify-space-between">
        <v-col 
          v-for="(site, index) in sites"
          :key="index"
          cols="5"
        >
          <v-row>
            <v-col 
              cols="4" 
              class="pr-0"
            >
              <span class="text-body-1"> {{ site ? site.nom : '' }} </span>
            </v-col>
            <v-col 
              cols="5" 
              class="px-0 pl-2" 
            >
              <span class="text-caption"> {{ site ? site.adresse : '' }} </span>
            </v-col>
            <v-col 
              cols="3" 
              class="d-flex px-0 justify-end" 
            >
              <base-button-icon 
                icon="$edit" 
                class="d-flex align-start"
                @click="showRowCreateSite(); currentSite = site"
              />
              <base-button-icon 
                icon="$trashCan"
                class="d-flex align-start"
                @click="deleteCurrentSite(site)"
              />
            </v-col>
          </v-row>
          <v-divider class="black" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-button-add-element 
            v-if="showButtonAddElement"
            class="mb-5 mt-2"
            label="Ajouter un site"
            width="100%"
            icon-color="white"
            @click="createSiteForm"
          />
        </v-col>
      </v-row>
      <v-row v-if="rowCreateSiteDisplay">
        <v-col cols="12"> 
          <form-fields-text 
            v-model="currentSite.nom"
            field-label="Nom du campus" 
            placeholder="Nom du site"
            required
            @blur="updateCurrentSite(currentSite)"
          />
          <form-fields-text 
            v-model="currentSite.adresse" 
            placeholder="Adresse"
            field-label="Localisation"
            required
            @blur="updateCurrentSite(currentSite)"
          />
          <v-row class="justify-end">
            <v-col cols="3">
              <form-fields-text 
                v-model="currentSite.code_postal" 
                placeholder="Code Postal"
                required
                number-integer
                @blur="saveEntite"
                @change="updateCurrentSite(currentSite)"
              />
            </v-col>
            <v-col cols="5">
              <form-fields-text 
                v-model="currentSite.ville" 
                placeholder="Ville"
                required
                @blur="updateCurrentSite(currentSite)"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-row>
          <v-col class="d-flex justify-center">
            <base-button
              width="440px"
              icon="$trashCan"
              @click="deleteCurrentSite(currentSite)"
            >
              Supprimer le site
            </base-button>
          </v-col>
          <v-col class="d-flex justify-center">
            <base-button
              width="440px"
              class="mb-9"
              @click="rowCreateSiteDisplay = false, showButtonAddElement = true"
            >
              Valider
            </base-button>
          </v-col>
        </v-row>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-fields-multi-select 
            v-model="secteur_activite"
            field-label="Secteur d'activité" 
            label="Liste des secteurs d'activité"
            :items="secteur_activites"
            @change="saveEntite"
          />
          <form-fields-text 
            v-if="type_organisation.nom === 'Établissement ESR'"
            v-model="nombre_etudiants_inscrits"
            field-label="Nombre d'étudiants inscrits"
            placeholder="Nombre d'étudiants inscrits"
            type="number"
            number-integer
            @change="saveEntite"
          />
          <form-fields-text
            v-model="nombre_postes_de_travail"
            field-label="Nombre de postes de travail informatiques"
            placeholder="Nombre de postes de travail informatiques"
            field-description="Nombres de postes connectés au réseau de l'université"
            type="number"
            class="mb-3"
            min-max
            number-integer
            @blur="saveEntite"
          />
          <form-fields-text
            v-model="nombre_utilisateurs"
            field-label="Nombre d'utilisateurs des infrastructures IT centralisées"
            placeholder="Nombre d'utilisateurs des infrastructures IT centralisées"
            field-description="Etudiants + personnels + hébergés"
            type="number"
            class="mb-3"
            min-max-utilisateur
            number-integer
            @blur="saveEntite"
          />
          <v-row class="mb-1">
            <v-col cols="4">
              <form-fields-label
                label="Nombre de personnes * :"
              />
            </v-col>
            <v-col cols="4">
              <span class="text-h3"> Au service informatique </span>
              <form-fields-text
                v-model="nombre_total_etp"
                placeholder="Nombre de personnes au service informatique"
                class="mt-2"
                number-integer
                type="number"
                @blur="saveEntite"
              />
            </v-col>
            <v-col cols="4">
              <span class="text-h3"> Au service patrimoine </span>
              <form-fields-text
                v-model="nombre_etp_service_patrimoine"
                placeholder="Nombre de personnes au service patrimoine"
                class="mt-2"
                type="number"
                required
                number-integer
                @blur="saveEntite"
              />
            </v-col>
          </v-row>
          <form-fields-text
            v-model="nombre_collaborateurs"
            field-label="Nombre de collaborateurs IT affectés au bon fonctionnement de vos infrastructures IT"
            placeholder="Nombre de collaborateurs"
            field-description="Nombre ETP y compris gestion RH, encadrement. Infrastructure et production : Technicien d'exploitation, Technicien poste de travail, Technicien réseaux-télécoms, Administrateur d'outils / de systèmes / de réseaux-télécoms, Administrateur de bases de données, Intégrateur d'exploitation, Pilote d'exploitation, Expert systèmes d'exploitation / réseau télécom, Architecte technique."
            type="number"
            class="mb-1"
            @blur="saveEntite"
          />
          <form-fields-text
            v-model="surface_totale"
            field-label="Nombre de m² total de l'établissement"
            placeholder="Nombre de m² total de l'établissement"
            type="number"
            class="mb-1"
            number-integer
            input-suffix="m²"
            @blur="saveEntite"
          />
          <form-fields-textarea
            v-model="commentaire"
            field-label="Commentaires" 
            placeholder="Commentaires"
            type="textarea"
            class="mt-5"
            @blur="saveEntite"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import FormStepTitle from '@/components/FormStepTitle.vue'
import FormFieldsMultiSelect from '@/components/FormFieldsMultiSelect.vue'
import FormFieldsText from '@/components/FormFieldsText.vue'
import FormFieldsTextarea from '@/components/FormFieldsTextarea.vue'
import FormButtonAddElement from '@/components/FormButtonAddElement.vue'
import BaseButton from '@/components/BaseButton.vue'
import { mapState, mapActions, mapMutations } from 'vuex';
import { mapFields, mapMultiRowFields } from 'vuex-map-fields';
import BaseSubtitle from '@/components/BaseSubtitle.vue'
import BaseButtonIcon from '@/components/BaseButtonIcon.vue'
import FormFieldsLabel from '@/components/FormFieldsLabel.vue'

export default {
  name: "Entity",
  components: { 
    FormStepTitle,
    FormFieldsMultiSelect,
    FormFieldsText,
    FormFieldsTextarea,
    FormButtonAddElement,
    BaseButton,
    BaseSubtitle,
    BaseButtonIcon,
    FormFieldsLabel
  },
  data () {
    return { 
      rowCreateSiteDisplay: false,
      showButtonAddElement: true,
      currentSite: null,
    }
  },
  computed: {
    ...mapMultiRowFields('form', ['sites', 'esrs']),
    ...mapState('form', ['type_organisations', 'secteur_activites', 'entites', 'entite', 'esr']),
    ...mapFields('form', [
      'entite.type_organisation_id',
      'entite.type_organisation',
      'entite.site_nom_lieu',
      'entite.site_adresse',
      'entite.secteur_activite',
      'entite.nombre_postes_de_travail',
      'entite.nombre_utilisateurs',
      'entite.nombre_collaborateurs',
      'entite.nombre_total_etp',
      'entite.nombre_etp_service_patrimoine',
      'entite.dependance_directe_id',
      'entite.nombre_etudiants_inscrits',
      'entite.commentaire',
      'entite.esr_id',
      'entite.surface_totale',
    ]),
  },
  created() {
    this.fetchEntite();
    this.fetchTypeOrganisations();
    this.fetchSecteurActivites();
    this.fetchEntites();
    this.setNextView('/profile/specific-esr');
    this.fetchSites();
    this.fetchEsr();
    this.fetchEsrs();
  },
  methods: {
    ...mapMutations('stepper', ['setNextView']),
    ...mapActions('form', [
    'fetchTypeOrganisations',
    'fetchEntite',
    'fetchSecteurActivites',
    'fetchEntites',
    'createSite',
    'updateSite',
    'fetchSites',
    'deleteSite',
    'fetchEsrs',
    'fetchEsr', 
    'updateEntite',
    'saveEntite',
    ]),
    deleteCurrentSite(currentSite) {
      if (confirm('Êtes-vous sûr de vouloir supprimer ce site, cette action est irréversible.')) {
        this.deleteSite(currentSite.id);
      }
    },
    showRowCreateSite() {
      this.rowCreateSiteDisplay = true;
      this.showButtonAddElement = false
    },
    async createSiteForm() {
      this.showRowCreateSite();
      this.currentSite = await this.createSite();
    },
    async updateCurrentSite(site) {
      const currentSite = {
        nom: site.nom,
        id: site.id,
        code_postal: site.code_postal,
        adresse: site.adresse,
        prix_electricite_campus: site.prix_electricite_campus,
        prix_electricite_campus_ht: site.prix_electricite_campus_ht,
        ville: site.ville
      }
      this.updateSite(currentSite)
    }
  }
}
</script>
